.profile-container {
  display: flex;
}

.profile-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.profile-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detail-label {
  font-weight: bold;
  color: #555;
}

.profile-categories div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  border-radius: 20px;
  width: 150px;
  margin-bottom: 20px;
}

.profile-main {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.profile-reach {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.profile-reach h1 {
  font-size: 30px;
  margin: 5px;
}

.profile-reach div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.profile-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: 15px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  height: 75vh;
}

.social-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 10vh;
  width: 100%;
}

.social-detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  overflow-x: hidden;
  width: 100%;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #f9f9f9;
  padding: 25px;
  border-radius: 8px;
  width: 50vw;
  max-width: 90%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 90%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .profile-container {
    flex-direction: column;
    padding: 0;
  }

  .profile-left {
    flex: 1;
    width: 100%;
    max-height: 10vh;
    padding: 10px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .profile-photo {
    width: 80px;
    height: 80px;
  }

  .profile-details {
    padding: 0 10px;
    align-items: flex-start;
    justify-content: center;
  }

  .profile-details p {
    font-size: 15px;
    margin: 0;
  }

  .profile-details button {
    font-size: 10px;
    padding: 10px;
  }

  .profile-categories {
    display: none;
  }

  .profile-social {
    display: none;
  }

  .profile-main {
    flex: 3;
    width: 100%;
    border: none;
    height: auto;
    padding: 10px 0;
  }

  .profile-reach {
    flex-direction: row;
    margin: 1px;
  }

  .profile-reach div {
    display: flex;
    flex-direction: column;
    margin: 1px;
  }

  .profile-reach h1 {
    font-size: 20px;
    margin: 1px;
  }
}
