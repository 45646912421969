.job-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.job-container {
  display: flex;
  height: 80vh;
  width: 80vw;
  color: #283618;
  border: 2px solid #03045E;
}

.job-listing {
  flex: 1;
  border-right: 1px solid #03045E;
  overflow-y: auto;
}

.job-listing-item {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #03045E;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.job-listing-item:hover,
.job-listing-item.selected {
  background-color: #CAF0F8;
  box-shadow: inset 3px 0 0 #03045E;
}

.profile-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
}

.job-listing-info {
  display: flex;
  flex-direction: column;
}

.job-listing-info h3 {
  margin: 0;
  font-size: 1.1em;
}

.job-listing-info p {
  margin: 5px 0 0;
  font-size: 0.9em;
}

.job-details {
  flex: 2;
  padding: 20px;
  overflow-y: auto;
}

.job-subtitle {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.job-details h1 {
  font-size: 2em;
  margin-top: 0;
}

.job-details h2 {
  margin-top: 20px;
  font-size: 1.2em;
}

.job-details p,
.job-details ul {
  margin-top: 10px;
}

.job-details ul {
  list-style-type: disc;
  padding-left: 20px;
}

.job-details li {
  margin-bottom: 10px;
}

.job-description-container {
  position: relative;
}

.business-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.business-details a {
  text-decoration: none;
  color: #000;
}

.close-icon {
  display: none;
}

.trash-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
}

.trash-icon:hover {
  color: #ff0000;
}

.modal-answers {
  width: 100%;
}

.modal-answers textarea {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 14px;
  resize: vertical;
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-applicant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  transition: background-color 0.3s, box-shadow 0.3s;
  border-bottom: 1px solid #0077B6;
}

.job-applicant img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}

.job-applicant:hover {
  background-color: #CAF0F8;
  box-shadow: inset 3px 0 0 #03045E;
}

.job-a a {
  text-decoration: none;
  color: #000;
}

@media (max-width: 768px) {
  .job-main {
    width: 100vw;
  }

  .job-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: 0px;
  }

  .job-listing {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #606c38;
  }

  .job-details {
    flex: 0;
  }

  .job-detail-popup {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.3s ease;
    z-index: 1001;
    height: 70vh;
    overflow-x: auto;
    overflow-y: scroll;
    border-top: 3px solid #606c38;
  }

  .job-detail-popup.show {
    bottom: 0;
  }

  .job-detail-popup h1 {
    font-size: 1.5em;
    margin-top: 0;
  }

  .job-subtitle {
    width: 90%;
  }

  .job-detail-desc {
    width: 90%;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
  }

  .backdrop.show {
    display: block;
  }

  .close-icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 55px;
    background: none;
    border: none;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
  }

  .close-icon:hover {
    color: #000;
  }
}
