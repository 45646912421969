.login-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/login_backgrounds_lightened/1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  width: 25vw;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.login-body button {
  margin-top: 20px;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-buttons button {
  margin: 10px;
}

.login-img-container {
  flex: 4;
  overflow: hidden;
  height: 100%;
}

.login-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
}

.login-form-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form-main h1 {
  font-size: 45px;
  margin-bottom: 40px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-button {
  margin-top: 30px;
}

.reg-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 5px;
  /* color: #fff; */
  /* border-radius: 10px; */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px; */
}

.reg-footer a,
.reg-footer span {
  margin: 5px;
  font-weight: 700;
  text-shadow: (0, 0, 0, 0.3) 0px 19px 38px;
}

.reg-footer a:hover,
.reg-footer span:hover {
  color: #ff0000;
  cursor: pointer;
}

.reg-footer p,
.reg-footer a {
  margin: 5px;
  text-shadow: (0, 0, 0, 0.3) 0px 19px 38px;
}

.reg-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.reg-logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
}

.reg-body {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reg-body h1 {
  font-size: 50px;
}

.reg-card {
  display: flex;
}

.card {
  margin: 10px;
  width: 200px;
  height: 300px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #caf0f8;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-size: 25px;
  color: #001845;
}

.card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reg-arrow {
  transition: transform 0.5s ease;
}

.card:hover .reg-arrow {
  transform: translateX(15px);
}

.reg-img-container {
  flex: 4;
  overflow: hidden;
  height: 100%;
}

.reg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reg-comp-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.reg-comp-body {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reg-comp-body h1 {
  font-size: 50px;
}

.reg-img-comp-container {
  flex: 1.2;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg-comp-img {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.login-header button {
  margin: 20px;
}

.login-footer {
  display: flex;
  flex-direction: column;
}

.reg-footer-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-reset-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .login-main {
    flex-direction: column;
  }

  .login-body {
    flex: 1;
  }

  .login-img-container {
    display: none;
  }

  .login-form-main {
    flex: 1;
  }

  .login-form {
    width: 80%;
  }

  .form-input input {
    width: 100%;
  }

  .reg-main {
    flex-direction: column;
  }

  .reg-body {
    flex: 1;
  }

  .reg-img-container {
    display: none;
  }

  .reg-card {
    flex-direction: column;
  }

  .card {
    width: 80vw;
  }

  .reg-comp-main {
    flex-direction: column;
    background-image: none;
    height: auto;
  }

  .reg-comp-body {
    flex: 1;
  }

  .reg-comp-body h1 {
    font-size: 40px;
    padding-top: 40px;
  }

  .reg-primary {
    display: flex;
    flex-direction: column;
  }

  .input-secondary {
    flex-direction: column;
  }

  .auth-button {
    margin-bottom: 20px;
  }

  .reg-img-comp-container {
    display: none;
  }

  .password-reset-container {
    max-width: 100%;
    padding: 10px;
  }
}
