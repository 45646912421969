html {
  scrollbar-width: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Basier circle", -apple-system, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #001845;
  background-color: #f5faff;
}

body::-webkit-scrollbar {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.button-54 {
  background-color: #001845;
  border: 0 solid #606c38;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle", -apple-system, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 5px;
}

.button-54:hover {
  background-color: #023e8a;
  color: #fefae0;
}

.button-54:disabled {
  background-color: #023e8a;
  color: #fefae0;
  cursor: not-allowed;
}

a {
  text-decoration: none;
}

.navbar {
  height: 4vh;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.search-container {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}

.navbar-search {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 100px;
  width: 300px;
}

.search-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.search-result {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.search-result:hover {
  background-color: #f2f0eb;
}

.search-result:last-child {
  border-bottom: none;
}

.logo {
  font-size: 30px;
  font-weight: 700;
  color: #001845;
}

.logo-side {
  color: #5f646f;
}

.logo-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.button-mobile {
  display: none;
}

.navbar-middle {
  font-size: 40px;
  display: flex;
  align-items: center;
}

.navbar-middle a {
  text-decoration: none;
  color: #5c677d;
}

.navbar-middle h6 {
  display: inline;
  padding: 10px;
}

.navbar-items {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 15px;
}

.navbar-items li,
img {
  margin-left: 10px;
}

.hamburger {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.home {
  text-align: center;
  height: 100vh;
  padding-top: 1px;
}

.loggedin-home {
  text-align: center;
  height: 90vh;
  padding-top: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home h1 {
  margin: 0px;
}

.home h2 {
  margin: 0px;
}

.home-body {
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.title {
  font-size: 120px;
  letter-spacing: 7px;
  color: #001845;
}

.title span {
  color: #001845;
}

.home-flex {
  display: flex;
  height: 90vh;
  margin: 0px 10px;
}

.home-about {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.home-about h2 {
  font-size: 5rem;
}

.home-about p {
  width: 25vw;
  font-size: 1.5rem;
  color: #3763ab;
  text-align: justify;
}

.home-flex-left {
  flex: 50%;
  display: flex;
  flex-direction: column;
}

.home-flex-right {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-flex-right img {
  width: 60%;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 20px 0px;
  text-align: center;
}

.footer .row {
  margin: 1% 0%;
  padding: 0.5% 0%;
  color: black;
  font-size: 1em;
}

.footer-icons {
  margin: 0px 10px;
  font-size: 25px;
}

.footer-icons :hover {
  color: #3763ab;
}

.footer .row a {
  text-decoration: none;
  color: black;
  transition: 0.5s;
}

.footer .row ul li {
  display: inline-block;
  margin: 0px 30px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
}

@media (max-width: 768px) {
  .navbar-middle {
    display: none;
  }

  .navbar-items {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 6vh;
    left: 0;
  }

  .navbar-items.open {
    display: flex;
  }

  .navbar-items a {
    margin: 10px 0;
    text-align: center;
  }

  .search-container {
    display: none;
  }

  .logo-profile {
    display: none;
  }

  .button-mobile {
    display: block;
  }

  .hamburger {
    display: block;
    font-size: 30px;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .hamburger.rotate {
    transform: rotate(90deg);
  }

  .home-flex-right {
    display: none;
  }

  .home-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home-about h1 {
    font-size: 1rem;
    padding: 5px 10px;
    margin-bottom: 10px;
  }

  .home-flex-left h2 {
    font-size: 3rem;
  }

  .home-card-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    gap: 10px;
  }

  .home-card {
    flex: 0 0 100%;
    scroll-snap-align: start;
    margin-right: 10px;
  }

  .card-1,
  .card-2,
  .card-3 {
    display: none;
  }

  .home-card-container {
    scroll-behavior: smooth;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-links {
    display: none;
  }

  .footer .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: center;
  }

  .footer .row a i {
    margin: 0% 3%;
  }
}







/* Commons */

.form-input {
  margin: 10px;
}

.form-input input,
.form-input textarea,
.form-input select {
  width: 400px;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  border: 0px solid #344e41;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form-input input[type="file"] {
  display: none;
}

.form-input select {
  width: 422px;
  height: 60px;
}

.form-input textarea {
  resize: none;
}

.input-label {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 700;
}

.reg-secondary {
  display: flex;
  flex-direction: column;
}

.reg-secondary label {
  margin: 10px;
  margin-right: 50px;
}

.button-file {
  font-size: 15px;
}

.input-secondary h4 {
  margin: 0;
  margin-bottom: 5px;
}

.input-secondary p {
  font-size: 14px;
  color: #ff0000;
  max-width: 200px;
}

.input-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reg-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reg-form-container {
  display: flex;
  flex-direction: column;
}

.reg-primary {
  display: grid;
  grid-template-columns: auto auto;
}


/* Modal */

.modal-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.modal-body {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
}

.close-modal {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* .modal-body input {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 14px;
}

.modal-body textarea {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 14px;
  resize: none;
} */
